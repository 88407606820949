import {RoleDto} from "@reside/reside-api-app";
import {AdminNavigationItem} from "../types";

/**
 * Generates the administration menu tabs based on the facility ID.
 *
 * @param {string} facilityId - The ID of the facility to open facility settings tab for.
 * @returns {Array<AdminNavigationItem>} An array of objects representing the tabs in the administration menu.
 *
 */
export const getAdministrationMenuTabs = (
  facilityId: string,
): AdminNavigationItem[] => [
  {
    name: "Users",
    link: "/admin/users",
    id: "usersLink",
    roles: [
      "RESIDE_ADMIN",
      "ORGANIZATION_ADMIN",
      "FACILITY_ADMIN",
      "ADMISSIONS_DIRECTOR",
      "REGIONAL_ADMIN",
    ] as RoleDto.NameEnum[],
  },
  {
    name: "Organizations",
    link: "/admin/organizations",
    id: "organizationsLink",
    roles: ["RESIDE_ADMIN", "ORGANIZATION_ADMIN"] as RoleDto.NameEnum[],
  },
  {
    name: "Facility Settings",
    link: `/admin/facility/${facilityId}/facility-settings`,
    id: "facilitySettingsLink",
    roles: [
      "RESIDE_ADMIN",
      "ORGANIZATION_ADMIN",
      "FACILITY_ADMIN",
      "ADMISSIONS_DIRECTOR",
      "REGIONAL_ADMIN",
    ] as RoleDto.NameEnum[],
    disabled: !facilityId, // Due to facilities not being fetched yet, for a brief moment on initial page load facilityId is undefined. The property prevents navigation to this link until the facilityId is set.
  },
  {
    name: "Feature Flags",
    link: "/admin/feature-flags",
    id: "featureFlagsLink",
    roles: ["RESIDE_ADMIN"] as RoleDto.NameEnum[],
  },
  {
    name: "Hub & Spoke",
    link: "/admin/hub-and-spoke",
    id: "hubAndSpokeLink",
    roles: ["RESIDE_ADMIN"] as RoleDto.NameEnum[],
  },
];

import React from "react";
import {DropdownMenu, DropdownMenuItem} from "@reside/ui";
import {AdministrationMenuDropdownButton} from "./AdministrationMenuDropdownButton";
import {getAdministrationMenuTabs} from "./constants";
import {AdministrationMenuProps} from "../types";

/** Administration-related tabs. */
export const AdministrationMenu = ({
  role,
  firstFacilityId,
}: AdministrationMenuProps): JSX.Element => {
  const administrationMenuTabs = getAdministrationMenuTabs(firstFacilityId);

  return (
    <DropdownMenu
      trigger={(open: boolean) => (
        <AdministrationMenuDropdownButton open={open} />
      )}
    >
      {administrationMenuTabs
        .filter(tab => tab.roles.includes(role)) // Only show tabs that the user has access to.
        .map(tab => {
          return (
            <DropdownMenuItem
              asLink
              disabled={tab?.disabled}
              key={tab.id}
              to={tab.link}
              data-test-id={tab.id}
            >
              {tab.name}
            </DropdownMenuItem>
          );
        })}
    </DropdownMenu>
  );
};
